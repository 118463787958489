import React, { Fragment } from 'react'
import { css } from '@emotion/core';
import MenuItem from './MenuItem'

const CoreMenu = () => <Fragment>
  <MenuItem
    slug="/blog"
    text="Blog"
  />
  <MenuItem
    slug="/guides"
    text="Guides"
  />
  <MenuItem
    slug="/about"
    text="About"
  />
  <MenuItem
    slug="/contact"
    text="Contact"
  />
</Fragment>
  export default CoreMenu