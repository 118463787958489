/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from 'emotion-theming'
import { Container } from '@material-ui/core'
import { css } from '@emotion/core'

import Header from "./MainComponents/header"
import Footer from "./MainComponents/footer"
import "./layout.css"
import "./normalize.css"

const Layout = ({ children, PageHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const theme = {
    colors: {
      green: '#679d38',
      green_transparent: '#679d38e0',
      white: '#ffffff',
      black: '#212529',
      black_transparent: "#252525e3",
      lgrey: '#bfbfbf',
      dgrey: '#3a3a3a'
    },
    breakpoints: {
      md: (css) => `@media (max-width : 960px) { ${css} }`,
      sm: (css) => `@media (max-width: 600px) { ${css} }`,
    },
    boxShadow: {
      lgrey: 'box-shadow: 2px 2px 5px 0px #bfbfbf',
      dgrey: 'box-shadow: 2px 2px 5px 0px #3a3a3a;'
    },
    line: (color = '#679d38', width = '100') => `&::after{
      content: '';
      width: ${width}px;
      height: 2px;
      background-color: ${color};
      display: block;
      margin: 8px auto;
    }`,
    blogPosts: `
      h1,h2,h3,h4,h5,h6{
        font-weight: 700;
      }
      h1 {
        font-size: 38px;
      }
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 32px;
      }
      h4 {
        font-size: 30px;
      }
      h5 {
        font-size: 28px;
      }
      h6 {
        font-size: 24px;
      }

      `
  }

  return (
    <div
      css={css`
        overflow: hidden;
        position: relative;
      `}
    >
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        {
          PageHeader &&
          <PageHeader />
        
        }
        <Container>
          <main>{children}</main>
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
