import React, { useState, createRef, useEffect } from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import MenuItem from './MenuItem'
import CoreMenu from './CoreMenu'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const MobileMenu = ({ data }) => {
  const [openValue, setOpenValue] = useState(false);
  var targetRef = React.createRef();
  var targetElement = null;
  targetElement = targetRef.current;
  useEffect(() => {
    if (!openValue) {
      enableBodyScroll(targetRef.current);
    } else {
      disableBodyScroll(targetRef.current);
    }

  }, [openValue])
  return (<div
    css={theme => css`
      display: none;
      ${theme.breakpoints.sm('display: block;')}
    `}
  >
    <div
      onClick={() => {
        setOpenValue(!openValue);
      }}
      css={theme => css`
      height: 100%;
      width: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 10;
      cursor: pointer;
      .menu-cross{
        width: 30px;
        height: 2px;
        background-color: ${theme.colors.dgrey};
        margin-bottom: 4px;
        display: block;
        z-index: 10;
        position: relative;
        opacity: 1;
        transition: all 0.3s ease-in;
      }
      ${openValue &&
        `.menu-cross-1 {
            transform: rotate(45deg);
            top: 3px;
            
        }
         .menu-cross-3{
          transform: rotate(135deg);
          bottom: 3px;
        }
        .menu-cross-2 {
          display: none;
          opacity: 0;
        }
        `
      }
    `}
    >
      <span
        className="menu-cross menu-cross-1"
      ></span>
      <span
        className="menu-cross menu-cross-2"
      ></span>
      <span
        className="menu-cross menu-cross-3"
      ></span>
    </div>
    <div
      css={theme => css`
        position: absolute;
        width: 100vw;
        background-color: white;
        z-index: 9;
        top: 0;
        left: ${openValue ? '0' : '100%'};
        opacity: ${openValue ? '1' : '0'};
        transition: all 0.3s ease-in;
        height: 100vh;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      `}
      ref={targetRef}
      className="mobile-menu-container"
    >
      <ul
        css={theme => css`
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
      `}
      >
        <CoreMenu />
        {
          data.allWordpressCategory.edges.map(({ node }) => {
            return (
              <MenuItem
                slug={`/blog/${node.slug}`}
                text={node.name}
                className="menu-category-item"
                onClick={() => {
                  console.warn({ openValue })
                  if (!openValue) {
                    enableBodyScroll(targetRef.current);
                  } else {
                    disableBodyScroll(targetRef.current);
                  }
                }}
              />
            )
          })
        }
      </ul>
    </div>
  </div>
  )
}

export default MobileMenu