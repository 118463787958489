/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, url, is404 = false }) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        file(relativePath: { eq: "images/standardHeader.jpg" }) {
          publicURL
        }
      }
    `
  )

  if(url === undefined && !is404 ){
    throw new Error(`this needs a url tag - ${title}`)
  };

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image ? image : site.siteMetadata.siteUrl + file.publicURL
  const metaURL = url ? site.siteMetadata.siteUrl + url : site.siteMetadata.siteUrl
  const metaTitle = title ? title : 'Green Finance Guide | Guide to sustainable green investments'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle.replace(/&#8217;/g, "'")}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defaultTitle={'Green Finance Guide | Guide to sustainable green investments'}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `twitter:image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: metaURL
        }
      ].concat(meta)}>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap" rel="stylesheet" />
        {
          !is404 &&
          <link href={metaURL} rel="canonical"/>
        }
      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
