import React from 'react'
import { css } from '@emotion/core'
import { Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Grid } from '@material-ui/core'

const FooterMenuItem = ({ to, text }) => <Link to={to}>
  <p
    css={theme => css`
      color:${theme.colors.white}
    `}
  >
    {text}
  </p>
</Link>

const Footer = () => {
  const data = useStaticQuery(graphql`
  query{
    twitterLogo: file(relativePath: { eq: "images/twitter.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 75){
          ...GatsbyImageSharpFluid
        }
      }
    }
    emailLogo: file(relativePath: { eq: "images/mail.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 75){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`
  );
  return (
    <footer
      css={theme => css`
        background-color: ${theme.colors.green};
        color: ${theme.colors.white};
        margin-top: 30px;
        padding: 20px 0;
        p {
          margin: 0;
        }
      `}
    >
      <Container>
        <Grid container>
          <Grid item sm={12} md={4}
            css={theme => css`
              width: 100%;
              display: flex;
              align-items: center;
              ${theme.breakpoints.md('text-align: center; padding-bottom: 6px;')}
            `}
          >
            <p
              css={css`
                width: 100%;
              `}
            >© {new Date().getFullYear()} Green Finance Guide</p>

          </Grid>
          <Grid item sm={12} md={4}
            css={css`
              width: 100%;
              display: flex;
              align-items: center;
            `}
          >
            <div
              css={theme => css`
                display: flex;
                justify-content: space-between;
                max-width: 280px;
                margin: 0 auto;
                width: 100%;
                ${theme.breakpoints.md('text-align: center; padding-bottom: 6px; ')}
              `}
            >
              <FooterMenuItem
                to={'/blog'}
                text={'Blog'}
                />
              <FooterMenuItem
                to={'/guides'}
                text={'Guides'}
                />
              <FooterMenuItem
                to={'/about'}
                text={'About'}
                />
              <FooterMenuItem
                to={'/contact'}
                text={'Contact'}
                />
            </div>
          </Grid>
          <Grid item sm={12}md={4}
            css={theme => css`
              width: 100%;
            `}
          >
            <div
              css={theme => css`
                width: 100%;
                display: flex;
                justify-content: flex-end;
                ${theme.breakpoints.md('justify-content: center;')}
                a {
                  margin: 0 6px;
                  display: flex;
                  align-items: center;
                }
              `}
            >
              <a href="https://twitter.com/GreenFinGuide">
                <Img
                  fluid={data.twitterLogo.childImageSharp.fluid}
                  css={css`
                    width: 22px;
                  `}
                />
              </a>
              <a href="mailto:contact@greenfinanceguide.com">
                <Img
                  fluid={data.emailLogo.childImageSharp.fluid}
                  css={css`
                      width: 18px;

                    `}
                />
              </a>
            </div>
            <div
              css={theme => css`
                display: flex;
                justify-content: flex-end;
                a {
                  margin-left: 10px;
                }
                ${theme.breakpoints.md('justify-content: center;')}
              `}
            >
              <FooterMenuItem
                to={'/privacy-policy'}
                text={'Privacy Policy'}
              />
              <FooterMenuItem
                to={'/cookie-declaration'}
                text={'Cookie Declaration'}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )}

export default Footer