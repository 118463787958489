import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from '@emotion/core'
import { Container } from '@material-ui/core'
import { useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const Header = ({ siteTitle }) => { 
  const data = useStaticQuery(graphql`
    query {
      headerLogo: file(
        relativePath: { eq: "images/gfg_logo_white_background.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allWordpressCategory(sort: {fields: count, order: DESC}, limit: 5) {
        edges {
          node {
            name
            slug
            count
          }
        }
      }
    }
  `)

  return (
    <header
      css={theme => css`
        background-color: ${theme.colors.white};
        padding: 12px 0;
        `
      }
    >
      <Container>
        <div
          css={css`
            display: flex;
            justify-content: space-between;`
          }
        >
          <div>
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <Img 
              fluid={data.headerLogo.childImageSharp.fluid}
              alt="Green Finance Guide"
              css={theme => css`
                width: 300px;
                ${theme.breakpoints.sm('width: 225px;')}
              `} />
            </Link>
          </div>
          <DesktopMenu data={data} />
          <MobileMenu data={data} />
        </div>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
