import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

const MenuItem = ({ slug, text, className = null, onClickFn = null }) => <li>
  <Link
    to={slug}
    className={className}
    onClick={() => {
      onClickFn()
    }}
  >
    <p
      css={theme => css`
      color: ${theme.colors.black};
      font-weight: 400;
      font-family: 'Lato';
      margin-left: 14px;
      ${theme.breakpoints.sm(`
        font-size: 25px;
        margin: 6px 8px;
        ${theme.line()}
       `)}
    `}
    >{text}</p>
  </Link>
</li>

export default MenuItem